
export const columns_config = () => {
	return [{
			name: "SKU",
			nameEn:"SKU",
			data: 'goodsSku',
			type: 'text',
		},
		{
			name: "关联编号",
			nameEn:"Relation No.",
			data: 'relaGoodsSku',
			type: 'text',
		},
		// {
		// 	name: "备注",
		// 	nameEn:"Remark",
		// 	data: 'remark',
		// 	type: 'text',
		// },
		{
			name: "ID",
			data: 'id',
			type: 'text',
			readOnly: true
		},
	];
}

export const nestedHeadersTop_config = () => {
	return [];
}