<template>

	<div class="mainTem">
		<div class="buyerHeader">
			<el-page-header @back="goBack">
				<!-- <template slot="title">title</template> -->
				<template slot="content">
					<span>{{$t('i18nn_f085596674018f3e')}}</span>SKU
					
					<el-link type="primary" icon="el-icon-question" target="_blank" :href="'https://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/helpDoc/RelationSKu.pdf'" style="padding:0 10px">{{$t('i18nn_90aa9ed0f2de2aa5')}}</el-link>
					<!-- <template v-if="10 == orderType"></template>
					<template v-else-if="20 == orderType"></template>
					<template v-else-if="30 == orderType">{{ $t('i18nn_6b1945319eb18d5f') }}</template>
					<template v-else-if="40 == orderType">{{$t('Storage.nav.A_undertakes_to')}}</template>
					<template v-else-if="50 == orderType">{{ $t('i18nn_199f5bded0b23976') }}</template>
					<template v-else-if="60 == orderType">{{$t('Storage.nav.My_packing_list')}}</template> -->
				</template>
			</el-page-header>

		</div>


		<div style="padding:10px; background-color: #FFFFFF;">

			<el-tabs v-model="activeName">
				<el-tab-pane :label="$t('i18nn_c996cb376f253cb5')" name="first">
					<skuRelationHotTable ref="skuRelationHotTable"></skuRelationHotTable>
				</el-tab-pane>
				<el-tab-pane :label="$t('i18nn_844521f361060167')" name="second">
					<!-- <transferOrderSingleAdd @submitSuccess="goBack"></transferOrderSingleAdd> -->
					<skuRelationSingleAdd ref="skuRelationSingleAdd"></skuRelationSingleAdd>
				</el-tab-pane>
			</el-tabs>
		</div>

		<div class="submit-footer">
			<!-- <el-button type="primary" plain @click="dialogFile = false">{{$t('i18nn_4e9fc68608c60999')}}</el-button> -->
			<el-button type="primary" icon="el-icon-tickets" @click="submitForm()">{{$t('i18nn_4ce9979bfb6576d9')}}</el-button>
		</div>
	</div>

</template>
<script>
	import skuRelationHotTable from '@/components/StorageCenter/WSkuProduct/WSkuRelation/skuRelationHotTable.vue';
	import skuRelationSingleAdd from '@/components/StorageCenter/WSkuProduct/WSkuRelation/skuRelationSingleAdd.vue';
	export default {
		// props: {
		// 	openTime: {},
		// 	row: {},
		// 	// title:{},
		// 	// typeCode:{}
		// },
		components: {
			skuRelationHotTable,
			skuRelationSingleAdd
		},
		data() {
			return {
				activeName: "first",
				// dialogFile: false,
				// loadingUpload: false,
				loading: false,
				// filterData: {
				// 	id: ''
				// },
				// loading_load: false,
				// tableData: [],
			};
		},
		// watch: {
		// 	openTime: function(newVal, oldVal) {
		// 		console.log('openTime');
		// 		this.initData();
		// 		//初始化
		// 		this.dialogFile = true;
		// 	}
		// },
		//创建时
		created() {
			this.initData();
		},
		//编译挂载前
		mounted() {

		},
		methods: {

			goBack() {
				this.$router.go(-1);
			},

			initData() {
				if (this.$route.query && this.$route.query.id) {
					this.activeName = "second"
				}
				// this.$nextTick(() => {
				// 	// this.$refs.skuRelationHotTable.initHotTable();
				// 	this.$refs.skuRelationHotTable.clear();
				// 	// if (this.row && this.row.id) {
				// 	// 	this.$refs.skuRelationHotTable.loadData([this.row])
				// 	// }
				// });
				// this.tableData = [];
				// this.getListData();
			},
			//提交信息
			submitForm() {
				this.$nextTick(() => {
					let formData = [];
					if ('first' == this.activeName) {
						formData = this.$refs.skuRelationHotTable.getData();
					} else {
						formData = this.$refs.skuRelationSingleAdd.getData();
					}
					// let formData = this.$refs.skuRelationHotTable.getData();
					if (!formData.id) {
						this.postData(this.$urlConfig.WhSkuRelationAdd, formData, () => {
							console.log('submitSuccess');
							// this.dialogFile = false;
							this.$message.success(this.$t('tips.submitSuccess'));
							// this.$emit('submitSuccess');
							this.goBack();
						});
					} else {
						this.postData(this.$urlConfig.WhSkuRelationEdit, formData[0], () => {
							// this.dialogFile = false;
							this.$message.success(this.$t('tips.submitSuccess'));
							// this.$emit('submitSuccess');
							this.goBack();
						});
					}
				});
			},
			//提交信息
			postData(url, formData, callback) {
				this.loading = true;
				let HttpType = {};
				HttpType = this.$http.put(url, formData);
				// }
				HttpType.then(({
					data
				}) => {
					// console.log(this.$t('i18nn_bc868e024b80d2e3'));
					// console.log(data);
					this.loading = false;
					if (200 == data.code) {
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						// 	type: 'success'
						// });

						callback();
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					// console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
					});
				});
			},
			//请求分页数据
			// getListData() {
			// 	this.loadingUpload = true;
			// 	this.$http
			// 		.get(this.$urlConfig.WhFileUploadList + '/' + this.row.id + '/'+this.typeCode, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log(this.$t('i18nn_4195755cd53e871a'));
			// 			console.log(data);
			// 			this.loadingUpload = false;
			// 			if (200 == data.code) {
			// 				this.tableData = data.rows;
			// 			} else {
			// 				this.$alert(this.$t('tips.queryErrorFailed'), this.$t('tips.tipsTitle'), {
			// 					type: 'warning'
			// 				});
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.loadingUpload = false;
			// 			this.$alert(this.$t('tips.queryRequestFailed'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		});
			// },
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
